import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const TableCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .comparison-table {
        width: 100%;
        margin-bottom: ${theme.spacing.md};

        @media screen and (max-width: 542px) {
          overflow: scroll;
        }

        .table-main {
          border: 1px solid #dcdcdc;
          border-top: 0;
          padding: 0;
          margin: 0;
          border-collapse: collapse;
          width: 100%;

          thead {
            width: 100%;

            td {
              padding: ${theme.spacing.md};
              margin: 0;
              border-top: 1px solid #dcdcdc;
              border-bottom: 4px solid ${theme.colors.primary.base.hex};
              text-align: center;
              color: ${theme.colors.dark.base.hex};
            }

            td:first-of-type {
              text-align: left;

              @media screen and (max-width: 542px) {
                box-shadow: 9px 0 5px -1px rgba(0, 0, 0, 0.17);
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #dcdcdc;

              td {
                text-align: center;
                padding: ${theme.spacing.sm} ${theme.spacing.md};
                min-height: 59px;
                color: ${theme.colors.dark.base.hex};

                sup {
                  vertical-align: top;
                }
              }

              td:first-of-type {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-left: 0;
                border-right: 0;
                text-align: left;

                @media screen and (max-width: 542px) {
                  box-shadow: 9px 0 5px -1px rgba(0, 0, 0, 0.17);
                }
              }
            }
          }

          &.align-text-left {
            td {
              text-align: left;
            }
          }
        }

        .table-after {
          text-align: left;
          font-size: 12px;
          color: ${theme.colors.dark.base.hex};
        }
      }
    `}
  />
))

export default TableCss
